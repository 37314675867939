import { StylesConfig } from 'react-select';

type OptionType = {
  value: string;
  label: string;
};

export const customStyles = (value: any): StylesConfig<OptionType, true> => {
  const hasValue = value?.length;
  return {
    container: (base: Record<string, unknown>) => ({
      ...base,
      borderRadius: '0',
    }),
    control: (base: Record<string, unknown>) => ({
      ...base,
      borderRadius: '0',
      borderColor: '#fff',
      cursor: 'pointer',
    }),
    menuList: (base: Record<string, unknown>) => ({
      ...base,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.02), 0px 30px 60px rgba(0, 0, 0, 0.08)',
      fontSize: '16px',
      padding: '10px 0',
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      borderRadius: 0,
    }),
    option: (base: Record<string, unknown>) => ({
      ...base,
      position: 'relative',
      backgroundColor: '#fff',
      cursor: 'pointer',
      fontSize: '16px',
      padding: '15px 24px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
    }),
    valueContainer: (base: Record<string, unknown>) => ({
      ...base,
      paddingTop: '0px',
    }),
    placeholder: (base: Record<string, unknown>) => ({
      ...base,
      display: 'block',
      color: hasValue ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.35)',
      fontSize: hasValue ? '14px' : '16px',
      fontWeight: hasValue ? 400 : 500,
      textTransform: hasValue ? 'none' : 'uppercase',
      ...(hasValue && {
        marginTop: '-10px',
        position: 'absolute',
      }),
    }),
    dropdownIndicator: (base: Record<string, unknown>) => ({
      ...base,
      color: 'rgba(0, 0, 0, 0.6)',
      ...(value?.length ? { display: 'none' } : {}),
    }),
    multiValue: (base: Record<string, unknown>) => ({
      ...base,
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#fff',
      paddingLeft: '0px',
    }),
    multiValueLabel: (base: Record<string, unknown>) => ({
      ...base,
      paddingLeft: '0px',
    }),
    multiValueRemove: (base: Record<string, unknown>) => ({ ...base, display: 'none' }),
  };
};
