import React, { useEffect, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { Check } from 'react-feather';
import classnames from 'classnames';
import { customStyles } from './customStyles';

import * as styles from './Dropdown.module.scss';

interface DropdownProps {
  options: { label: string; value: string }[];
  placeholder: string;
  onChange: (val: OptionType[], type?: string) => void;
  closeMenuOnSelect?: boolean;
  filteredValueByParams?: OptionType[],
}

export type OptionType = {
  value: string;
  label: string;
};

const CustomValueContainer = ({ children, ...props }: any) => {
  const { ValueContainer, Placeholder } = components;
  return (
    <ValueContainer {...props}>
      {!children[0].length ? <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder> : ''}
      <div className={classnames(styles.dropdownValues, 'is-right')}>
        {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
      </div>
    </ValueContainer>
  );
};

const CustomOption = (props: any) => {
  const { Option } = components;
  return (
    <Option className="is-flex is-justify-content-space-between" {...props}>
      <span
        style={{
          fontWeight: props.isSelected ? 600 : 400,
          color: props.isSelected ? 'rgba(0, 0, 0, 0.95)' : 'rgba(0, 0, 0, 0.65)',
        }}
      >
        {props.data.label}
      </span>
      <Check
        style={{
          fontWeight: 800,
          padding: '4px',
          backgroundColor: props.isSelected ? '#003359' : '#fff',
          color: '#fff',
          border: '1px solid rgba(0, 0, 0, 0.14)',
        }}
        size={24}
      />
    </Option>
  );
};

const Dropdown = ({ options, placeholder, onChange, closeMenuOnSelect = true, filteredValueByParams = [] }: DropdownProps) => {
  const [selectValue, setSelectValue] = useState<OptionType[] | null>(null);

  const handleChange = (value: any) => {
    setSelectValue(value);
    onChange(value);
  };

  useEffect(() => {
    if (filteredValueByParams) {
      setSelectValue(filteredValueByParams)
    }
  }, [JSON.stringify(filteredValueByParams)])

  return (
    <div className={styles.customDropdown}>
      <ReactSelect
        closeMenuOnSelect={closeMenuOnSelect}
        styles={customStyles(selectValue)}
        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null, Option: CustomOption }}
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
        options={[...options].sort((a, b) => a.label.localeCompare(b.label))}
        isMulti
        value={selectValue}
        hideSelectedOptions={false}
        isSearchable={false}
      />
    </div>
  );
};

export default Dropdown;
